import React from "react"
// MUI
import {
    Box, ButtonBase, Divider, FormControl, FormControlLabel,
    Grid,
    MenuItem, Paper, Slider, Stack, Switch,
    Typography
} from "@mui/material";
import {
    ArrowCircleRightOutlined,
    FormatColorFill,
    FormatShapesRounded,
    FormatSize,
    HighlightAltRounded, Palette
} from "@mui/icons-material";
// Components
import {ColorPicker, Accordion, Select} from "../../utils";
// CSS
import "./TemplateDesignPanel.css"
// Translation
import {useTranslation} from "react-i18next";
// Provider data
import {useUser} from "../../../provider/UserProvider";
import useRestrictedFonts from "../../../hooks/useRestrictedFonts";


// Fonts list
const fonts = [
    { name: "Arial", value: "Arial" },
    { name: "Barlow Condensed", value: "Barlow Condensed"},
    { name: "Bebas Neue", value: "Bebas Neue" },
    { name: "Dosis", value: "Dosis" },
    { name: "Fjalla One", value: "Fjalla One" },
    { name: "Gotham", value: "Gotham" },
    { name: "Jost", value: "Jost" },
    { name: "Poppins", value: "Poppins"},
    { name: "Roboto", value: "Roboto" },
    { name: "Roboto Slab", value: "Roboto Slab" },
    { name: "Staatliches", value: "Staatliches" },
    { name: "Viga", value: "Viga" },
]

export default function TemplateDesignPanel(props: any) {

    const {t} = useTranslation(["templateEditor", "common"])
    const {templateDesign, setTemplateDesign} = props;
    const {org} = useUser()
    const restrictedFonts = useRestrictedFonts()
    // Accordions management
    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    const [backgroundExtType, setBackgroundExtType] = React.useState<any>("recommended")
    const [backgroundIntType, setBackgroundIntType] = React.useState<any>("recommended")

    React.useEffect(() => {
        if (templateDesign.backgroundExt.isGradient) {
            setBackgroundExtType("gradient")
        }
    }, [])

    const returnAvailableFonts = () => {

        let availableFonts: any

        if (!!restrictedFonts) availableFonts = fonts.concat(restrictedFonts)
        else availableFonts = fonts

        return availableFonts.map((font: any) => (
            <MenuItem key={font.value} value={font.value}>
                {t(font.name)}
            </MenuItem>
        ))
    }

    // renders
    const renderPalettesSection = () => {

        const palettes = [
            {
                background: "#e8baba",
                card: "#fff",
                titles: "#e14c4c",
                accent: "#e14c4c",
                lighter: "#e77b7b",
                darker: "#943333",
                action: "#ad1919",
                texts: "#000",
                buttonsTexts: "#fff",
                buttonsTextHover: "#fff"
            },
            {
                background: "#f1ebeb",
                card: "#e9eef5",
                titles: "#49aed2",
                accent: "#1c6f8a",
                lighter: "#49aed2",
                darker: "#1a4c60",
                action: "#2a6e8a",
                texts: "#000",
                buttonsTexts: "#fff",
                buttonsTextHover: "#034364"
            },
            {
                background: "#575454",
                card: "#252526",
                titles: "#f3f3f8",
                accent: "#c99227",
                lighter: "#f8d89e",
                darker: "#835c16",
                action: "#835c16",
                texts: "#ffffff",
                buttonsTexts: "#fff",
                buttonsTextHover: "#c99227"
            }
        ]

        const handleSelectPalette = (palette: any) => {


            setBackgroundExtType("singleColor")
            setBackgroundIntType("singleColor")

            setTemplateDesign({
                ...templateDesign,
                backgroundInt: {
                    backgroundColor: palette.card,
                    isGradient: false,
                },
                backgroundExt: {
                    ...templateDesign.backgroundExt,
                    backgroundColor: palette.background
                },
                fontNameHolder: {
                    fontName: "Fjalla One",
                    fontColor: palette.titles
                },
                fontCardTitles: {
                    fontName: "Fjalla One",
                    fontColor: palette.titles
                },
                fontCardTexts: {
                    fontName: "Roboto",
                    fontColor: palette.texts
                },
                buttons: {
                    ...templateDesign.buttons,
                    font: {
                        fontName: "Roboto",
                        fontColor: palette.buttonsTexts
                    },
                    backgroundColor: palette.darker,
                    backgroundOverColor: palette.lighter,
                    textOverColor: palette.buttonsTextHover,
                },
                icons: {
                    socialNetworkColor: palette.darker,
                    sectionColor: palette.darker,
                    actionIconColor: palette.accent,
                    actionIconOverColor: palette.darker
                },
                templateOption: {
                    ...templateDesign.templateOption,
                    anglesColor: palette.accent,
                },
            })
        }

        return (
            <Accordion
                title={t("palettes")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<Palette />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start"}} spacing={3}>
                    <Grid item xs={12}>
                        <Typography>
                            {t("palettes_intro")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        {
                            palettes.map((palette: any, i: number) => (
                                <Grid item key={i}>
                                    <ButtonBase onClick={() => handleSelectPalette(palette)} sx={{ transition: "0.15s ease-in-out", "&:hover": { filter: "brightness(90%)" } }}>
                                        <Paper variant={"outlined"} sx={{ p: 2 }}>
                                            <Stack direction={"row"} spacing={"4px"}>
                                                {[palette.background, palette.card, palette.titles, palette.lighter, palette.darker].map((color: any, j: number) => (
                                                    <Box sx={{ backgroundColor: color, height: 25, width: 25, border: "1px solid #8d8d8d", borderRadius: 1}} key={j}></Box>
                                                ))}
                                            </Stack>
                                        </Paper>
                                    </ButtonBase>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderBackgroundSection = () => {

        const backgroundTypes = [ "recommended", "singleColor", "gradient" ]

        const backgroundRecommendedColors = [
            { name: t("rec_white"), color: "#eeeeee" },
            { name: t("rec_dusty"), color: "#DDDEDD" },
            { name: t("rec_pastel"), color: "#B9D4E7" },
            { name: t("rec_dawn"), color: "#F9E0D4" },
            { name: t("rec_sky"), color: "#4F93C2" },
            { name: t("rec_lila"), color: "#BEC4DA" },
        ]

        const cardBackgroundTypes = [ "recommended", "singleColor" ]

        const cardBackgroundRecommendedColors = [
            { name: t("clear"), color: "#ffffff" },
            { name: t("dark"), color: "#000000" },
        ]

        const handleBackgroundTypeChange = (type: any) => {
            if (type === "gradient") {
                setBackgroundExtType("gradient")
                setTemplateDesign({...templateDesign, backgroundExt: { ...templateDesign.backgroundExt, isGradient: true } })
                return
            } else {
                setTemplateDesign({...templateDesign, backgroundExt: { ...templateDesign.backgroundExt, isGradient: false } })
                setBackgroundExtType(type)
            }
        }

        return (
            <Accordion
                title={t("background")}
                expanded={accordionFocus === 1}
                onChange={handleAccordionFocus(1)}
                icon={<FormatColorFill />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start"}} spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("background_color")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Select
                            fullWidth
                            label={t("background_color_type")}
                            value={backgroundExtType}
                            onChange={(e: any) => handleBackgroundTypeChange(e.target.value)}
                        >
                            {
                                backgroundTypes && backgroundTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {t(type)}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>

                    {
                        // RECOMMENDED COLORS
                        backgroundExtType === "recommended" ?
                        <Grid item xs={12} md={3}>
                            <Select
                                fullWidth
                                label={t("color")}
                                value={templateDesign.backgroundExt.backgroundColor}
                                onChange={(e: any) => setTemplateDesign({...templateDesign, backgroundExt: { ...templateDesign.backgroundExt, backgroundColor: e.target.value }})}
                                placeholder={t("select_recommended_color")}
                            >
                                {
                                    backgroundRecommendedColors && backgroundRecommendedColors.map((color) => (
                                        <MenuItem key={color.color} value={color.color}>
                                            {color.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>

                        // SINGLE COLOR
                        : backgroundExtType === "singleColor" ?
                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"}>
                                <ColorPicker
                                    initialColor={templateDesign.backgroundExt.backgroundColor}
                                    onChangeComplete={(color: any) =>
                                        setTemplateDesign({...templateDesign, backgroundExt:
                                                { ...templateDesign.backgroundExt, backgroundColor: color }
                                        })
                                    }
                                    label={t("primary_color")}
                                />
                            </Grid>

                        // GRADIENT
                        : backgroundExtType === "gradient" ?
                            <>
                                <Grid item xs={12} md={3} alignContent={"center"} display={"flex"}>
                                    <ColorPicker
                                        initialColor={templateDesign.backgroundExt.gradientColor1}
                                        onChangeComplete={(color: any) =>
                                            setTemplateDesign({...templateDesign, backgroundExt:
                                                    { ...templateDesign.backgroundExt, gradientColor1: color }
                                            })
                                        }
                                        label={t("primary_color")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} alignContent={"center"} display={"flex"}>
                                    <ColorPicker
                                        initialColor={templateDesign.backgroundExt.gradientColor2}
                                        onChangeComplete={(color: any) =>
                                            setTemplateDesign({...templateDesign, backgroundExt:
                                                    { ...templateDesign.backgroundExt, gradientColor2: color }
                                            })
                                        }
                                        label={t("secondary_color")}
                                    />
                                </Grid>
                            </>
                        : null
                    }

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("card_background_color")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Select
                            fullWidth
                            label={t("card_background_color_type")}
                            value={backgroundIntType}
                            onChange={(e: any) => setBackgroundIntType(e.target.value)}
                        >
                            {
                                cardBackgroundTypes && cardBackgroundTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {t(type)}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>

                    {
                        backgroundIntType === "recommended" ?
                        <Grid item xs={12} md={3}>
                            <Select
                                fullWidth
                                label={t("color")}
                                value={templateDesign.backgroundInt.backgroundColor}
                                onChange={(e: any) => setTemplateDesign({...templateDesign, backgroundInt: { ...templateDesign.backgroundInt, backgroundColor: e.target.value }})}
                                placeholder={t("select_recommended_color")}
                            >
                                {
                                    cardBackgroundRecommendedColors && cardBackgroundRecommendedColors.map((color) => (
                                        <MenuItem key={color.color} value={color.color}>
                                            {color.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>

                        // SINGLE COLOR
                        : backgroundIntType === "singleColor" ?
                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"}>
                                <ColorPicker
                                    initialColor={templateDesign.backgroundInt.backgroundColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, backgroundInt: { ...templateDesign.backgroundInt, backgroundColor: color}})}
                                    label={t("primary_color")}
                                />
                            </Grid>

                        : null
                    }
                </Grid>
            </Accordion>
        )
    }

    const renderFontsSection = () => {
        return (
            <Accordion
                title={t("fonts")}
                expanded={accordionFocus === 2}
                onChange={handleAccordionFocus(2)}
                icon={<FormatSize />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start"}} spacing={3}>

                    {/* TITLE */}
                    <Grid item xs={11} md={6}>
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("cardholder_name")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Select
                                    fullWidth
                                    label={t("font_used")}
                                    value={templateDesign.fontNameHolder.fontName}
                                    onChange={(e: any) => setTemplateDesign({...templateDesign, fontNameHolder: { ...templateDesign.fontNameHolder, fontName: e.target.value }})}
                                >
                                    {returnAvailableFonts()}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} alignContent={"center"} display={"flex"}>
                                <ColorPicker
                                    initialColor={templateDesign.fontNameHolder.fontColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, fontNameHolder: { ...templateDesign.fontNameHolder, fontColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* SECTION TITLES */}
                    <Grid item xs={11} md={6}>
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("section_titles")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Select
                                    fullWidth
                                    label={t("font_used")}
                                    value={templateDesign.fontCardTitles.fontName}
                                    onChange={(e: any) => setTemplateDesign({...templateDesign, fontCardTitles: { ...templateDesign.fontCardTitles, fontName: e.target.value }})}
                                >
                                    {returnAvailableFonts()}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} alignContent={"center"} display={"flex"}>
                                <ColorPicker
                                    initialColor={templateDesign.fontCardTitles.fontColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, fontCardTitles: { ...templateDesign.fontCardTitles, fontColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={11} md={6}>
                        <Grid container spacing={2} justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("text")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Select
                                    fullWidth
                                    labelId={"textFont"}
                                    label={t("font_used")}
                                    value={templateDesign.fontCardTexts.fontName}
                                    onChange={(e: any) => setTemplateDesign({...templateDesign, fontCardTexts: { ...templateDesign.fontCardTexts, fontName: e.target.value }})}
                                >
                                    {returnAvailableFonts()}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} alignContent={"center"} display={"flex"}>
                                <ColorPicker
                                    initialColor={templateDesign.fontCardTexts.fontColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, fontCardTexts: { ...templateDesign.fontCardTexts, fontColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderButtonsIconsSection = () => {

        return (
            <Accordion
                title={t("buttons_and_icons")}
                expanded={accordionFocus === 3}
                onChange={handleAccordionFocus(3)}
                icon={<HighlightAltRounded />}
            >
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("buttons")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Select
                                    fullWidth
                                    label={t("font_used")}
                                    value={templateDesign.buttons.font.fontName}
                                    onChange={(e: any) =>
                                        setTemplateDesign({...templateDesign, buttons: { ...templateDesign.buttons, font: { ...templateDesign.buttons.font, fontName: e.target.value}}})
                                    }
                                >
                                    {returnAvailableFonts()}
                                </Select>
                            </Grid>

                            <Grid item xs={8}></Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("button_font_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.buttons.font.fontColor}
                                    onChangeComplete={(color: any) =>
                                        setTemplateDesign({...templateDesign, buttons: { ...templateDesign.buttons, font: { ...templateDesign.buttons.font, fontColor: color}}})
                                    }
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("button_font_hover_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.buttons.textOverColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, buttons : { ...templateDesign.buttons, textOverColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("button_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.buttons.backgroundColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, buttons: { ...templateDesign.buttons, backgroundColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("button_hover_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.buttons.backgroundOverColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, buttons: { ...templateDesign.buttons, backgroundOverColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                                    {t("icons")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("icon_social_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.icons.socialNetworkColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, icons: {...templateDesign.icons, socialNetworkColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}>{t("icon_section_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.icons.sectionColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, icons: {...templateDesign.icons, sectionColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}><ArrowCircleRightOutlined fontSize={"inherit"} /> {t("icon_action_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.icons.actionIconColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, icons: {...templateDesign.icons, actionIconColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} alignContent={"center"} display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body1"} fontWeight={500}><ArrowCircleRightOutlined fontSize={"inherit"} /> {t("icon_action_hover_color")}</Typography>
                                <ColorPicker
                                    initialColor={templateDesign.icons.actionIconOverColor}
                                    onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, icons: {...templateDesign.icons, actionIconOverColor: color }})}
                                    label={t("color")}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderCardShapeSection = () => {

        const dividers = [
            { name: "straight", value: 0 },
            { name: "biais", value: 1 },
            { name: "biaisReverse", value: 2 },
            { name: "waves", value: 3 },
            { name: "curve", value: 4 },
        ]

        return (
            <Accordion
                title={t("card_shape_and_borders")}
                expanded={accordionFocus === 4}
                onChange={handleAccordionFocus(4)}
                icon={<FormatShapesRounded />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("card_borders")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant={"body1"} fontWeight={500}>{t("card_borders_radius")}</Typography>
                        <Slider
                            valueLabelDisplay={"auto"}
                            value={parseInt(templateDesign.templateOption.angles)}
                            step={1}
                            min={0}
                            max={25}
                            onChange={(e: any) => {
                                setTemplateDesign({...templateDesign, templateOption: { ...templateDesign.templateOption, angles: `${e.target.value}` } });
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography variant={"body1"} fontWeight={500}>{t("card_borders_color")}</Typography>
                        <ColorPicker
                            initialColor={templateDesign.templateOption.anglesColor}
                            onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, templateOption: { ...templateDesign.templateOption, anglesColor: color }})}
                            label={t("color")}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("card_divider")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Select
                            fullWidth
                            label={t("divider")}
                            value={templateDesign.separator}
                            onChange={(e: any) => setTemplateDesign({...templateDesign, separator: e.target.value})}
                        >
                            {
                                dividers && dividers.map((divider: any, index: number) => (
                                    <MenuItem key={index} value={divider.value}>
                                        {t(divider.name)}
                                    </MenuItem>
                                ))
                            }

                        {/*  Specific separator used for a client - must rely on org  */}
                            {
                                (org && (org.organisation_id === "4b746dda-5798-44b0-ba36-80398b0a1c46")) &&
                                <MenuItem value={5}>
                                    Vague_wb
                                </MenuItem>
                            }


                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("card_shadow")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant={"body1"} fontWeight={500}>{t("card_shadow_force")}</Typography>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={templateDesign.templateOption.shadow}
                                        onChange={(e) => setTemplateDesign({...templateDesign, templateOption: {...templateDesign.templateOption, shadow: e.target.checked} })}
                                    />
                                }
                                label={templateDesign.templateOption.shadow ? t("enabled") : t("disabled")}
                            />
                        </FormControl>

                    </Grid>

                    {
                        templateDesign.templateOption.shadow &&
                        <Grid item xs={12} md={3}>
                            <Typography variant={"body1"} fontWeight={500}>{t("card_shadow_color")}</Typography>
                            <ColorPicker
                                initialColor={templateDesign.templateOption.shadowColor}
                                onChangeComplete={(color: any) => setTemplateDesign({...templateDesign, templateOption: {...templateDesign.templateOption, shadowColor: color }})}
                                label={t("color")}
                            />
                        </Grid>
                    }
                </Grid>
            </Accordion>
        )
    }

    return (
        <>
            <Box className={"designBaseWrapper"}>
                {renderPalettesSection()}
                {renderBackgroundSection()}
                {renderFontsSection()}
                {renderButtonsIconsSection()}
                {renderCardShapeSection()}
            </Box>
        </>
    )
}